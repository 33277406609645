import 'react';
import { css } from '@emotion/core';
import Theme from '../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import TitleBlock from '../common/components/title-block';
import DescriptionBlock from '../common/components/description-block';
import { Tab } from '@blueprintjs/core';
import AttributesBlock from './components/attributes-block';
import TableBlock from './components/table-block';
import { Image, Transformation } from 'cloudinary-react';
import { IOffering } from '../../../modules/products-display/types';
import { CustomTabs } from '../../ui';

interface IDetailedProduct extends IOffering {
    isOverlay: boolean;
}

// const DetailedProductButtons:React.FunctionComponent<IDetailedProductButtons> = ({amount, setAmount}) => {
//
//   return (
//     <div css={css`
//       position: absolute;
//       display: flex;
//       justify-content: space-between;
//       padding-bottom: 0.5rem;
//       bottom: 0;
//       left: 0.5rem;
//       right: 0.5rem; `} >
//       <AddRemoveButtons size={'extraLarge'}
//                         amount={amount}
//                         isActive={true}
//                         setAmount={setAmount} />
//       <AddToBasketButton amount={amount} />
//     </div>
//   )
// }

const DetailedProduct: React.FunctionComponent<IDetailedProduct> = ({
    isOverlay,
    description,
    details,
    name
}) => {
    const screenWidth: number = window.innerWidth;
    console.log('screenWidth: ', screenWidth);

    const theme: Theme = useTheme();

    return (
        <article
            css={css`
                display: flex;
                flex-direction: column;
                height: 100vh;
                width: 100%;
                ${isOverlay && 'position: relative;'}
                ${isOverlay && 'bottom: 40px;'}
            ${theme.layout.mq[1]} {
                    flex-direction: row;
                    height: 30rem;
                }
                ${theme.layout.mq[2]} {
                    height: 37.75rem;
                }
            `}>
            <div
                css={css`
                    flex: 6;
                    ${isOverlay && 'width: 37.75rem;' + 'height: 37.75rem;'}
                `}>
                <Image
                    cloudName={'coresine'}
                    alt={'The picture of the product'}
                    secure={true}
                    publicId={`products/test/farm-winter`}
                    css={css`
                        width: 100%;
                        height: 100%;
                        :after {
                            content: '';
                            display: block;
                            padding-bottom: 100%;
                        }
                        ${isOverlay && 'border-radius: 0.5rem 0 0 0.5rem;'}
                    `}>
                    <Transformation flags={'progressive:semi'} />
                </Image>
            </div>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    flex: 4;
                    padding: 0.5rem;
                    position: relative;
                    background-color: white;
                    padding-left: 1rem;
                    padding-bottom: 3.5rem;
                    border-radius: 0.5rem;
                `}>
                <TitleBlock isOverlay={isOverlay} title={name} mode={'detailed'} />
                {/*<PartnerBlock business={business} mode={'detailed'} />*/}
                <AttributesBlock mode={'detailed'} />
                <CustomTabs
                    id={'tabs'}
                    renderActiveTabPanelOnly={true}
                    animate={true}
                    large={false}>
                    <Tab
                        title={'Description'}
                        id={'description'}
                        panel={<DescriptionBlock description={description} />}
                    />
                    {details?.nutrition && (
                        <Tab
                            title={'Nutritions'}
                            id={'nutritions'}
                            panel={<TableBlock nutrition={details.nutrition} />}
                        />
                    )}
                </CustomTabs>
                {/*{ isOverlay ? <DetailedProductButtons/>*/}x{/*:*/}
                {/*<div css={css`*/}
                {/*  position: fixed;*/}
                {/*  left: 1rem;*/}
                {/*  right: 0.5rem;*/}
                {/*  bottom: 0.5rem;*/}
                {/*`} >*/}
                {/*  <AddToBasketButton fill={true} amount={0} />*/}
                {/*</div>}*/}
            </div>
        </article>
    );
};

export default DetailedProduct;
