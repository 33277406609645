import 'react';
import { css } from '@emotion/core';
import Theme from '../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';

interface IDescriptionBlock {
    description: string;
}

const DescriptionBlock: React.FunctionComponent<IDescriptionBlock> = ({ description }) => {
    const theme: Theme = useTheme();

    return (
        <p
            css={css`
                font-size: ${theme.fontSize.tertiary};
                color: ${theme.colors.tertiary};
                ${theme.layout.mq[1]} {
                    font-size: ${theme.fontSize.secondary};
                }
            `}>
            {description}
        </p>
    );
};

export default DescriptionBlock;
