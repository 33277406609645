import React, { ReactNode } from 'react';
import { css } from '@emotion/core';
import { Card, Menu, Popover, Position } from '@blueprintjs/core';
import { useTheme } from 'emotion-theming';
import Theme from '../../../utils/theming/theme-type';
import HorizontallyScrollableRowOfCards from './components/horizontally-scrollable-row-of-cards';
import PostContent from './components/post-content';
import { Button } from '@components/ui';

// TODO Add more tp this type
type GroupCardType = 'row-of-cards' | 'post';

interface IGroupCard {
    type?: GroupCardType;
    headerTitle: string | ReactNode;
    content: any;
}

const GroupCard: React.FunctionComponent<IGroupCard> = ({ type, content, headerTitle }) => {
    const theme: Theme = useTheme();

    const [isMoreButtonVisible, setIsMoreButtonVisible] = React.useState<boolean>(false);

    const menu = (
        <Menu>
            <Menu.Item text={'Something new here'} icon={'warning-sign'} />
            <Menu.Item text={'Something new here'} icon={'warning-sign'} />
            <Menu.Item text={'Something new here'} icon={'warning-sign'} />
        </Menu>
    );

    const onMouseEnterHandler = (): void => {
        setIsMoreButtonVisible(true);
    };

    const onMouseLeaveHandler = (): void => {
        setIsMoreButtonVisible(false);
    };

    return (
        <Card
            css={css`
                padding: 0;
                width: 100%;
                max-width: 64rem;
            `}>
            <div
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 2.5rem;
                    border-bottom: 1px solid #cccccc;
                    padding: 0 1em;
                `}>
                <span
                    css={css`
                        font-size: ${theme.fontSize.default};
                        font-weight: bold;
                    `}>
                    {headerTitle}
                </span>
                <Popover
                    content={menu}
                    position={Position.BOTTOM}
                    css={css`
                        transition: opacity 0.2s ease-in-out;
                        opacity: ${isMoreButtonVisible ? '1' : '0'};
                    `}>
                    <Button minimal={true} icon={'more'} large={false} />
                </Popover>
            </div>
            {type === 'row-of-cards' && <HorizontallyScrollableRowOfCards content={content} />}
            {type === 'post' && <PostContent {...content} />}
        </Card>
    );
};

export default GroupCard;
