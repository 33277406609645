import 'react';
import { css } from '@emotion/core';
import Theme from '../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { Card } from '@blueprintjs/core';
import { Image, Transformation } from 'cloudinary-react';

import TitleBlock from '../common/components/title-block';
import AddressBlock from './components/address-block';
import ImageWrapper from '../common/components/image-wrapper';
import CardWrapper from '../common/components/card-wrapper';
import OpeningTimesBadge from './components/opening-times-badge';
import { Router } from '../../../utils/i18n/i18n';
import React from 'react';
import { IPartner } from '../../../services/partners-service/types';

interface IPartnerCard {
    hit: IPartner;
}

const PartnerCard: React.FunctionComponent<IPartnerCard> = ({ hit }) => {
    const theme: Theme = useTheme();
    // const [isDetailedCardOpen, setIsDetailedCardOpen] = useState<boolean>(false);

    // const onCloseHandler = () => {
    //   setIsDetailedCardOpen(false);
    // }

    const onClickHandler = () => {
        Router.push(`/detailed-partner/${hit.id}`);
    };

    return (
        <section>
            {/*<DialogWrapper>*/}
            {/*  <Dialog lazy={true}*/}
            {/*          canEscapeKeyClose={true}*/}
            {/*          canOutsideClickClose={true}*/}
            {/*          isCloseButtonShown={true}*/}
            {/*          title={''}*/}
            {/*          isOpen={isDetailedCardOpen}*/}
            {/*          onClose={onCloseHandler} >*/}
            {/*    <DetailedPartner isOverlay={true} />*/}
            {/*  </Dialog>*/}
            {/*</DialogWrapper>*/}
            <CardWrapper>
                <Card
                    interactive={true}
                    onClick={onClickHandler}
                    css={css`
                        height: 22.5rem;
                    `}>
                    <ImageWrapper>
                        <Image
                            cloudName={'coresine'}
                            alt={'The picture of the product'}
                            secure={true}
                            publicId={`businesses/${hit.images[0]}`}
                            css={css`
                                border-radius: 5px 5px 0 0;
                            `}>
                            <Transformation flags={'progressive:semi'} />
                        </Image>
                    </ImageWrapper>
                    <div
                        css={css`
                            flex: 2;
                            margin-top: 0.5rem;
                        `}>
                        <span
                            css={css`
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                ${theme.layout.mq[1]} {
                                    flex-direction: row;
                                }
                            `}>
                            <TitleBlock title={name} mode={'basic'} width={'75%'} />
                            <OpeningTimesBadge openingTimes={hit.openingTimes} />
                        </span>
                        <AddressBlock mode={'basic'} address={hit.address} width={'75%'} />
                    </div>
                </Card>
            </CardWrapper>
        </section>
    );
};

export default PartnerCard;
