import * as ActionTypes from './action.types';
import { Types as PartnerTypes } from '../../services/partners-service';
import { IPartner, IOffering } from './types';

export const changeSearchState = (newSearchState: string) => {
    return {
        type: ActionTypes.HOME_PAGE_CHANGE_SEARCH_STATE,
        payload: {
            newSearchState: newSearchState
        }
    };
};

export const changeResultsState = (resultsState: IOffering[]) => {
    return {
        type: ActionTypes.HOME_PAGE_CHANGE_RESULTS_STATE,
        payload: {
            resultsState: resultsState
        }
    };
};

export const detailsPartnerPageFetchSucceeded = (partner: PartnerTypes.IPartner) => {
    return {
        type: ActionTypes.DETAILED_PARTNER_PAGE_FETCH_PARTNER_SUCCESS,
        payload: {
            partner
        }
    };
};

export const detailsPartnerPageFetchFailed = (error: Error) => {
    return {
        type: ActionTypes.DETAILED_PARTNER_PAGE_FETCH_PARTNER_FAILURE,
        payload: {
            error: error
        }
    };
};

export const setHomePageTab = (newTabId: string) => {
    return {
        type: ActionTypes.HOME_PAGE_SET_TAB,
        payload: {
            homePageTab: newTabId
        }
    };
};
