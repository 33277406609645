import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../../utils/theming/theming';

const DialogWrapper = styled.div`
    > div {
        display: block;
        width: 45rem;
        height: 30rem;
        background-color: white;
        .bp3-dialog-header {
            box-shadow: none;
        }
        .bp3-dialog-header > button {
            z-index: 1;
        }
        ${theme.layout.mq[2]} {
            width: 67.5rem;
            height: 37.75rem;
        }
    }
`;

export default DialogWrapper;
