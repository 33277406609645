import React, { Dispatch } from 'react';
import { css } from '@emotion/core';
import { FilterBlock, ListsBlock } from './components';
import { Divider } from '@blueprintjs/core';
import { IShareableList } from '@services/shareable-lists-service/types';
import { authUserChecker, CognitoUserPayloadDTO } from '../../../auth';
import { Auth } from '@services/auth';

interface IQuickAccessBlock {
    homePageTab: string;
    setHomePageTab: Dispatch<string>;
    shareableLists: IShareableList[];
}

const QuickAccessBlock: React.FC<IQuickAccessBlock> = ({
    homePageTab,
    setHomePageTab,
    shareableLists
}) => {
    React.useEffect(() => {
        authUserChecker(Auth)
            .then((res) => {
                setUser(res);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const [user, setUser] = React.useState<CognitoUserPayloadDTO>();

    return (
        <aside
            className={'grid bg-white mt-16 gap-y-4'}
            css={css`
                position: sticky;
                top: 4rem;
                height: fit-content;
                grid-auto-rows: min-content;
            `}>
            <FilterBlock homePageTab={homePageTab} setHomePageTab={setHomePageTab} />
            <Divider />
            <ListsBlock shareableLists={shareableLists} user={user} />
        </aside>
    );
};

export default QuickAccessBlock;
