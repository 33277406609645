import React from 'react';
import { HorizontallyScrollableOneRowGrid } from '../../../common/wrappers';
import { IPartner, IOffering } from '../../../../modules/products-display/types';
import PartnerCardV2 from '../../partner-card/partner-card-v2';
import { ProductCard } from '../../product-card';

interface IHorizontallyScrollableRowOfCards {
    // partners?: IPartner[]
    // offerings?: IOffering[]
    content: (IPartner | IOffering)[];
}

export const isPartner = (maybePartner: any): maybePartner is IPartner => {
    return !!(maybePartner as IPartner).address;
};

export const isOffering = (maybeOffering: any): maybeOffering is IOffering => {
    return !!(maybeOffering as IOffering).details;
};

const HorizontallyScrollableRowOfCards: React.FunctionComponent<IHorizontallyScrollableRowOfCards> = ({
    content
}) => {
    console.log('content: ', content);
    return (
        <HorizontallyScrollableOneRowGrid padding={'1rem'}>
            {content.map((element) => {
                if (isPartner(element)) {
                    return <PartnerCardV2 {...element} key={element._id} />;
                }
                if (isOffering(element)) {
                    return <ProductCard {...element} key={element._id} />;
                }
            })}
        </HorizontallyScrollableOneRowGrid>
    );
};

export default HorizontallyScrollableRowOfCards;
