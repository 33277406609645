import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { css } from '@emotion/core';
import { InputGroup } from '@blueprintjs/core';
import { useTranslation } from '../../../../utils/i18n/i18n';

const SearchBox = ({ currentRefinement, refine }) => {
    const { t } = useTranslation();

    const onChangeHandler = (event) => {
        refine(event.currentTarget.value);
    };

    return (
        <InputGroup
            value={currentRefinement}
            onChange={onChangeHandler}
            name={'search'}
            placeholder={t('products-display:home-page-search-bar-placeholder')}
            large={false}
            type={'search'}
            leftIcon={'search'}
            css={css`
                position: sticky;
                top: 3.25rem;
                min-width: 9rem;
                justify-self: center;
                z-index: 1;
            `}
        />
    );
};

const CustomSearchbox = connectSearchBox(SearchBox);

export default CustomSearchbox;
