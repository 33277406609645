import 'react';
import { css } from '@emotion/core';
import Theme from '../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { IPartner } from '../../../../modules/products-display/types';

interface IBusinessBlock {
    business: IPartner;
    mode: 'basic' | 'detailed';
}

const PartnerBlock: React.FunctionComponent<IBusinessBlock> = ({ business }) => {
    const theme: Theme = useTheme();

    const stopPropagator = (e) => {
        e.stopPropagation();
    };

    return (
        <span
            onClick={stopPropagator}
            css={css`
                a {
                    font-size: ${theme.fontSize.default};
                    ${theme.layout.mq[1]} {
                        font-size: ${theme.fontSize.default};
                    }
                }
            `}>
            {/*<Link href={`/partner/prisma-something`} className={'text-blue-500 hover:text-blue-800 hover:underline'} >*/}
            {business.name}
            {/*</Link>*/}
        </span>
    );
};

export default PartnerBlock;
