import { ActionCreator, Dispatch } from 'redux';
import * as Actions from './actions';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import { debounce } from 'throttle-debounce';
import { Router } from '../../utils/i18n/i18n';
import { GraphqlGatewayClient } from '../../services/graphql-gateway';
import {
    Types as PartnerTypes,
    Queries as PartnerQueries,
    Client as PSClient
} from '../../services/partners-service';

const searchStateToUrl = (searchState) => (searchState ? `/home?${qs.stringify(searchState)}` : '');

const debouncedOnSearchStateChange = debounce(1000, (searchState) => {
    const href = searchStateToUrl(searchState);
    Router.push(href, href, {
        shallow: true
    });
});

export const changeResults: ActionCreator<ThunkAction<any, any, any, any>> = (newResults: any) => {
    return async (dispatch: Dispatch) => {
        dispatch(Actions.changeResultsState(newResults));
    };
};

export const changeSearchState: ActionCreator<ThunkAction<any, any, any, any>> = (
    newSearchState: any
) => {
    return async (dispatch: Dispatch) => {
        dispatch(Actions.changeSearchState(newSearchState));
        debouncedOnSearchStateChange(newSearchState);
    };
};

export const setHomePageTab: ActionCreator<ThunkAction<any, any, any, any>> = (
    newTabId: string
) => {
    return async (dispatch: Dispatch) => {
        dispatch(Actions.setHomePageTab(newTabId));
    };
};

export const getPartnerDetailsById: ActionCreator<ThunkAction<any, any, any, any>> = (
    partnerId: string
) => {
    return async (dispatch: Dispatch) => {
        try {
            console.log('preparing to call gateway ...');
            GraphqlGatewayClient.call<PartnerTypes.ListPartnersQuery>(PartnerQueries.ListPartners)
                .then((partnersData) => PSClient.mapListPartnersQuery(partnersData))
                .then((partners) => console.log('partners: ', partners))
                .catch((err) => console.error(err));

            const partnerData = await GraphqlGatewayClient.call<PartnerTypes.GetPartnerByIdQuery>(
                PartnerQueries.GetPartnerById,
                {
                    id: partnerId
                }
            );
            const partner = PSClient.mapGetPartnerByIdQuery(partnerData);
            console.log('partner:', partner);
            if (partner != {}) {
                dispatch(
                    Actions.detailsPartnerPageFetchSucceeded(partner as PartnerTypes.IPartner)
                );
            } else {
                throw new Error('Returned partner was empty');
            }
        } catch (err) {
            console.error(err);
            dispatch(Actions.detailsPartnerPageFetchFailed(err));
        }
    };
};
