import { css } from '@emotion/core';
import { connect } from 'react-redux';
import Theme from '../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { Card, Intent, Text, Menu, Popover } from '@blueprintjs/core';
import TitleBlock from '../common/components/title-block';
import AddressBlock from './components/address-block';
import ImageWrapper from '../common/components/image-wrapper';
import CardWrapper from '../common/components/card-wrapper';
import OpeningTimesBadge from './components/opening-times-badge';
import React, { useState } from 'react';
import ActionsBlock from './components/actions-block';
import { ProgressiveImage } from '../../common';
import { AsyncOperations } from '../../../modules/shareable-lists';
import { IShareableList } from '@interfaces/shareable-lists';
import { PartnerDto } from '@services/partners-service/dto';
import { ListsMenu } from './components';
import { IconNames } from '@blueprintjs/icons';
import Router from 'next/router';
import { Button } from '@components/ui';
import { IPartner } from '@interfaces/partner';

interface IPartnerCard extends IPartner {
    hit: PartnerDto;
    fetchMyShareableListsList: (userId: string) => void;
    userId: string | undefined;
    hasFetchedShareableLists: boolean;
    shareableLists: IShareableList[];
    addPartnerToShareableListByIdAsync: (
        userId: string,
        partnerId: string,
        listId: string,
        listName: string
    ) => void;
}

const PartnerCardV2: React.FunctionComponent<IPartnerCard> = ({ hit, ...props }) => {
    const theme: Theme = useTheme();
    // const { latitude, longitude  } = usePosition({watch: true, enableHighAccuracy: true});

    const [isMoreButtonVisible, setIsMoreButtonVisible] = useState<boolean>(false);

    // const coordinates = { latitude: latitude, longitude: longitude };

    const onMouseEnterHandler = (): void => {
        setIsMoreButtonVisible(true);
    };

    const onMouseLeaveHandler = (): void => {
        setIsMoreButtonVisible(false);
    };

    const addPartnerToShareableListByIdAsyncWrapper = (
        partnerId: string,
        listId: string,
        listName: string
    ): void => {
        if (props.userId) {
            props.addPartnerToShareableListByIdAsync(props.userId, partnerId, listId, listName);
        }
    };

    const contextMenu = (
        <Menu>
            <Menu.Item text={'Add to list'} icon={IconNames.ADD} intent={Intent.PRIMARY}>
                <ListsMenu
                    hit={hit}
                    userId={props.userId}
                    shareableLists={props.shareableLists}
                    addPartnerToShareableListByIdAsync={addPartnerToShareableListByIdAsyncWrapper}
                />
            </Menu.Item>
            {/*<Menu.Item text={'Follow'} intent={Intent.PRIMARY} />*/}
        </Menu>
    );

    const detailedPartnerPageOpener = (): void => {
        if (hit?.name === 'Kellon Lähiruoka' || props?.name === 'Kellon Lähiruoka') {
            Router.push(`/partners/${hit.id}`);
        } else {
            return null;
        }
    };

    const propagationStopper = (event: any): void => {
        event.stopPropagation();
    };

    return (
        <section onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
            <CardWrapper>
                <Card
                    interactive={true}
                    onClick={detailedPartnerPageOpener}
                    css={css`
                        border-radius: 0.5rem !important;
                        ${theme.layout.mq[0]} {
                            height: auto !important;
                        }
                    `}>
                    <ImageWrapper>
                        <div
                            css={css`
                                position: absolute;
                                left: 0.3rem;
                                top: 0.3rem;
                                display: flex;
                                justify-content: flex-end;
                                right: 0.3rem;
                                z-index: 1;
                            `}>
                            {/*<TrulyLocalBadge />*/}
                            <section className={'flex justify-end'} onClick={propagationStopper}>
                                <Popover content={contextMenu}>
                                    <Button
                                        icon={'more'}
                                        minimal={true}
                                        large={false}
                                        intent={Intent.PRIMARY}
                                        css={css`
                                            transition: 0.2s ease-in-out;
                                            opacity: ${isMoreButtonVisible ? 1 : 0};
                                        `}
                                    />
                                </Popover>
                            </section>
                        </div>
                        <ProgressiveImage
                            src={hit?.images?.[0] || props?.images?.[0]}
                            alt={`The picture of the ${hit?.name || props?.name}`}
                            className={'rounded-t-lg'}
                        />
                    </ImageWrapper>
                    <div
                        css={css`
                            flex: 2;
                        `}>
                        <span
                            css={css`
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                ${theme.layout.mq[1]} {
                                    flex-direction: column;
                                }
                            `}>
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    align-items: center;
                                `}>
                                {/*<Text ellipsize={true}*/}
                                {/*      css={css`*/}
                                {/*        color: dark-gray;*/}
                                {/*        font-size: ${theme.fontSize.secondary};*/}
                                {/*        text-transform: capitalize;*/}
                                {/*      `} >*/}
                                {/*  {hit?.tags[0] || props?.tags?.[0]}*/}
                                {/*</Text>*/}
                                <OpeningTimesBadge
                                    openingTimes={hit?.openingTimes || props?.openingTimes}
                                />
                            </div>
                        </span>
                        <TitleBlock
                            title={hit?.name || props?.name}
                            mode={'basic'}
                            width={'100%'}
                        />
                        <Text
                            ellipsize={true}
                            css={css`
                                color: ${theme.colors.warning};
                            `}>
                            {hit?.tags[0] || props?.tags?.[0]}
                        </Text>
                        <div
                            css={css`
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                margin-top: 0.5rem;
                            `}>
                            <AddressBlock
                                mode={'basic'}
                                address={hit?.address || props?.address}
                                width={'60%'}
                            />

                            {/*{ coordinates.latitude && hit.address.location && !hit.address.location['coordinates'] ? <DistanceBlock*/}
                            {/*  startCoordinates={hit?.address.location || props?.address.location}*/}
                            {/*  endCoordinates={coordinates}*/}
                            {/*  address={hit?.address.address || props?.address.address}*/}
                            {/*/> : <div></div>}*/}
                        </div>
                        <div
                            css={css`
                                margin-top: 0.5rem;
                            `}>
                            <ActionsBlock
                                shareableLists={props.shareableLists}
                                userId={props.userId}
                                hit={hit}
                                addPartnerToShareableListByIdAsync={
                                    addPartnerToShareableListByIdAsyncWrapper
                                }
                            />
                        </div>
                    </div>
                </Card>
            </CardWrapper>
        </section>
    );
};

const mapStateToProps = (state) => {
    console.log('state.authState?.cognitoUserPayload: ', state.authState?.cognitoUserPayload);
    return {
        shareableLists: state.shareableListsPageState.lists,
        hasFetchedShareableLists: state.shareableListsPageState.hasFetched,
        userId: state.authState?.cognitoUserPayload
            ? state.authState?.cognitoUserPayload['custom:doc_id']
            : undefined
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addPartnerToShareableListByIdAsync: (
            userId: string,
            partnerId: string,
            listId: string,
            listName: string
        ) => {
            return dispatch(
                AsyncOperations.addPartnerToShareableListByIdAsync(
                    {
                        partners: {
                            partners: [
                                {
                                    partner_id: partnerId,
                                    added_by: userId
                                }
                            ]
                        }
                    },
                    listId,
                    listName
                )
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerCardV2);
