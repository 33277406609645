import React from 'react';
import { css, jsx } from '@emotion/core';
// import { indexName, searchClient } from '../../../instantsearch';
// import CustomSearchbox from '../custom-searchbox';
// import { InstantSearch } from 'react-instantsearch-dom'
// import { useTheme } from 'emotion-theming';
// import Theme from '../../../../../utils/theming/theme-type';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as AsyncOperations from '../../../operations';
// import CustomInfiniteProductsGridHits from '../custom-infinite-products-grid-hits';
import { NonIdealState } from '@blueprintjs/core';

interface IOfferingsPanel {
    onSearchStateChange: any;
    resultsState: any;
    searchState: any;
}

const OfferingsPanel: React.FunctionComponent<IOfferingsPanel> = (
    {
        /*searchState*/
        /*resultsState*/
        /*onSearchStateChange*/
    }
) => {
    // const theme: Theme = useTheme();

    return (
        <NonIdealState
            title={'Thank you for checking this out. But this feature is still under construction.'}
            description={'Come back soon!'}
            icon={'error'}
            css={css`
                height: 70vh;
            `}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        searchState: state.homePageState.searchState,
        resultsState: state.homePageState.resultsState
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onSearchStateChange: (newSearchState: string) =>
            dispatch(AsyncOperations.changeSearchState(newSearchState))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferingsPanel);
