import React from 'react';
import { GroupCard } from '../../group-card';

interface IUpdatesTab {}

const UpdatesTab: React.FunctionComponent<IUpdatesTab> = () => {
    return (
        <div className={'grid gap-2'}>
            <GroupCard
                type={'post'}
                headerTitle={'New Turkeys'}
                content={{
                    text:
                        'Big turkey will be available this Friday 20.11.2020. Follow me and get more updates on the newest additions'
                }}
            />
        </div>
    );
};

export default UpdatesTab;
