import React from 'react';
import { css } from '@emotion/core';
import { Tab, TabId } from '@blueprintjs/core';
import TitleBlock from '../common/components/title-block';
import OpeningTimesBadge from './components/opening-times-badge';
import ProductsTab from './components/products-tab';
import AboutTab from './components/about-tab';
import withRenderUI from '../../../hoc/render-ui-hoc';
import { PartnerDto } from '@services/partners-service/dto';
import { ImagesGrid, MaxWidthWrapper, TrulyLocalBadge } from '../../common';
import UpdatesTab from './components/updates-tab';
import { theme } from '../../../utils/theming/theming';
import { CustomTabs } from '../../ui';
import { QuickAccessContainer } from './components';
import ContactsBlock from './components/contacts-block';
import useMedia from 'use-media'; // requires a loader

interface IDetailedPartnerCard {
    partner: PartnerDto;
    error: boolean;
    isFetching: boolean;
}

const DetailedPartner: React.FunctionComponent<IDetailedPartnerCard | undefined> = ({
    partner
}) => {
    const [currentPanel, setCurrentPanel] = React.useState<TabId>('products');

    const isMobile: boolean = useMedia({ maxWidth: 640 });

    const tabChangeHandler = (newTab: TabId) => {
        setCurrentPanel(newTab);
    };

    return (
        <MaxWidthWrapper color={'white'}>
            <div
                className={'flex flex-col'}
                css={css`
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                    max-width: 100% !important;
                    min-height: 100vh;
                    ${theme.layout.mq[0]} {
                        margin-left: 5% !important;
                        margin-right: 5% !important;
                    }
                `}>
                <div
                    className={
                        'col-span-4 flex flex-col w-full sm:h-full sm:col-start-3 sm:mt-4 ' +
                        'sm:col-span-8 xl:col-start-4 xl:col-span-6'
                    }>
                    <div className={'grid grid-cols-12 sm:gap-4 m-0 w-full sm:flex-row'}>
                        <div
                            className={`col-span-12 border-b md:border-b-0 md:col-span-8`}
                            css={css`
                                height: min-content;
                                img {
                                    object-fit: contain !important;
                                }
                            `}>
                            <ImagesGrid photos={partner.images} />
                            <section
                                className={
                                    'flex flex-col w-full col-span-4 p-4 mt-2 sm:p-0 sm:col-span-5'
                                }>
                                <div
                                    id={'static-content'}
                                    className={'flex flex-col'}
                                    css={css`
                                        overflow-y: hidden;
                                    `}>
                                    {partner.name && (
                                        <div className={'flex items-center'}>
                                            <TitleBlock
                                                title={partner.name}
                                                mode={'detailed'}
                                                width={'100%'}
                                            />
                                            {partner.openingTimes && (
                                                <OpeningTimesBadge
                                                    openingTimes={partner.openingTimes}
                                                />
                                            )}
                                        </div>
                                    )}
                                    <TrulyLocalBadge width={'fit-content'} />
                                    {/*{ !isMobile && <>*/}
                                    {/*  <div className={'flex mt-4'} >*/}
                                    {/*    <Icon icon={IconNames.INFO_SIGN}*/}
                                    {/*          color={'gray'}*/}
                                    {/*          iconSize={ICONS_SIZE}*/}
                                    {/*          className={'mr-2'} />*/}
                                    {/*    <p> {  parser(partner.description.slice(0, 171)) } </p>*/}
                                    {/*  </div>*/}
                                    {/*</> }*/}
                                </div>
                                <div
                                    className={
                                        'col-span-9 flex-4 flex-col bg-white w-full rounded-b-lg'
                                    }
                                    css={css`
                                        position: sticky;
                                        top: 3rem;
                                        z-index: 1;
                                        border-bottom: 1px solid #e2e8f0;
                                        ${theme.layout.mq[0]} {
                                            border-bottom: 0 solid #e2e8f0;
                                        }
                                    `}>
                                    <CustomTabs
                                        id={'partner-tabs'}
                                        renderActiveTabPanelOnly={false}
                                        animate={true}
                                        defaultSelectedTabId={'products'}
                                        large={false}
                                        onChange={tabChangeHandler}
                                        css={css`
                                            .bp3-tab-list {
                                                display: flex;
                                                height: calc(auto - 1rem);
                                                border-bottom: 1px solid ${theme.colors.grey};
                                                > div {
                                                    margin-right: 1.75rem !important;
                                                    line-height: 2.5rem;
                                                }
                                            }
                                            .bp3-tab-indicator-wrapper {
                                                height: 2.5rem !important;
                                            }
                                            .bp3-tab-panel {
                                                padding: 0.5rem;
                                            }
                                        `}>
                                        <Tab id={'products'} title={'Products'} />
                                        <Tab id={'updates'} title={'Updates'} />
                                        <Tab id={'about'} title={'About'} />
                                        {isMobile && <Tab id={'contacts'} title={'Contacts'} />}
                                    </CustomTabs>
                                </div>
                                <div className={'flex flex-col pt-4'}>
                                    {currentPanel === 'products' && (
                                        <ProductsTab contact={partner.contact} />
                                    )}
                                    {currentPanel === 'updates' && <UpdatesTab />}
                                    {currentPanel === 'about' && (
                                        <AboutTab
                                            openingTimes={partner.openingTimes}
                                            description={partner.description}
                                        />
                                    )}
                                    {currentPanel === 'contacts' && isMobile && (
                                        <ContactsBlock
                                            iconSize={20}
                                            {...partner.contact}
                                            {...partner.address}
                                        />
                                    )}
                                </div>
                            </section>
                        </div>
                        <QuickAccessContainer partner={partner} />
                    </div>
                </div>
            </div>
        </MaxWidthWrapper>
    );
};

const DetailedPartnerWithUIRender = withRenderUI(DetailedPartner);

export default DetailedPartnerWithUIRender;
