import React from 'react';
import DetailedPartnerWithUIRender from '../../../components/cards/partner-card/detailed-partner-card';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as AsyncOperations from '../operations';
import { PartnerDto } from '@services/partners-service/dto';
import { IPartner } from '@interfaces/partner';

interface IPartnerPageIndex {
    getPartnerDetailsById: (partnerId: string) => void;
    detailedPartner: IPartner;
    error: any;
}

const PartnerPageIndex: React.FunctionComponent<IPartnerPageIndex> = ({ detailedPartner }) => {
    return (
        <main className={'flex flex-col mt-12 min-h-full bg-white'}>
            <DetailedPartnerWithUIRender
                error={false}
                isFetching={false}
                partner={new PartnerDto(detailedPartner)}
            />
        </main>
    );
};

const mapStateToProps = (state) => {
    return {
        detailedPartners: state.homePageState.detailedPartners,
        error: state.homePageState.error
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        getPartnerDetailsById: (partnerId: string) =>
            dispatch(AsyncOperations.getPartnerDetailsById(partnerId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerPageIndex);
