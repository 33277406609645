import React from 'react';
import { IShareableList } from '@services/shareable-lists-service/types';
import { css } from '@emotion/core';
import { CompactShareableListCard } from '../../../../../components/cards/shareable-list';
import { Divider, Intent, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { CognitoUserPayloadDTO } from '@services/auth';
import { Router } from '../../../../../utils/i18n/i18n';
import { Button } from '@components/ui';

interface IListsBlock {
    shareableLists: IShareableList[];
    user: CognitoUserPayloadDTO;
}

const RenderChecker: React.FC<IListsBlock> = ({ shareableLists, user }) => {
    const authRedirectHandler = () => {
        Router.push('/auth');
    };

    if (shareableLists && user) {
        return (
            <section
                className={'grid w-full mt-4'}
                css={css`
                    grid-auto-rows: min-content;
                    gap: 0.5rem 0;
                `}>
                {shareableLists.map((shareableList) => {
                    return <CompactShareableListCard shareableList={shareableList} />;
                })}
                <Divider />
                <Button
                    intent={Intent.PRIMARY}
                    icon={IconNames.PLUS}
                    fill={true}
                    // onClick={}
                    minimal={true}
                />
            </section>
        );
    }
    if (!shareableLists) {
        return (
            <NonIdealState
                title={'You have no no lists'}
                className={'mt-4'}
                description={'You do not have any lists create one'}
                action={<Button intent={Intent.PRIMARY} text={'Create new list'} minimal={true} />}
            />
        );
    }
    if (!user) {
        return (
            <NonIdealState
                title={'You are not authorized'}
                className={'mt-4'}
                description={'Authorize to get access to lists'}
                action={
                    <Button
                        intent={Intent.PRIMARY}
                        text={'Authorize'}
                        onClick={authRedirectHandler}
                        minimal={true}
                    />
                }
            />
        );
    }
};

const ListsBlock: React.FC<IListsBlock> = ({ shareableLists, user }) => {
    return (
        <section>
            <h3 className={'text-base font-bold'}> Shareable Lists </h3>
            <RenderChecker shareableLists={shareableLists} user={user} />
        </section>
    );
};

export default ListsBlock;
