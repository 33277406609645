import { AnyAction } from 'redux';
import { State } from 'react-redux';
import * as ActionTypes from './action.types';
import produce from 'immer';

const reducer = produce((state: State = { homePageTab: 'partners' }, action: AnyAction) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.HOME_PAGE_CHANGE_SEARCH_STATE: {
            const { newSearchState } = payload;
            return {
                ...state,
                searchState: newSearchState
            };
        }

        case ActionTypes.HOME_PAGE_CHANGE_RESULTS_STATE: {
            const { results } = payload;
            return {
                ...state,
                results: results
            };
        }

        case ActionTypes.DETAILED_PARTNER_PAGE_FETCH_PARTNER_SUCCESS: {
            const { partner } = payload;

            if (!state.detailedPartners) {
                return {
                    ...state,
                    detailedPartners: {
                        [partner._id]: partner
                    }
                };
            } else {
                const newState = state;
                newState.detailedPartners[partner._id] = partner;
                return newState;
            }
        }
        case ActionTypes.DETAILED_PARTNER_PAGE_FETCH_PARTNER_FAILURE: {
            const { error } = payload;
            return {
                ...state,
                error: error
            };
        }
        case ActionTypes.HOME_PAGE_SET_TAB: {
            const { homePageTab } = payload;
            return {
                ...state,
                homePageTab: homePageTab
            };
        }

        default: {
            return state;
        }
    }
});

export default reducer;
