import 'react';
import { css } from '@emotion/core';
import { H2, Tab } from '@blueprintjs/core';
import Theme from '../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import MaxWidthWrapper from '../../../../components/common/wrappers/max-width-wrapper';
import { InstantSearch } from 'react-instantsearch-dom';
import CustomInfiniteProductsGridHits from './custom-infinite-products-grid-hits';
import { searchClient, indexName } from '../../instantsearch';
import CustomSearchbox from './custom-searchbox';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as AsyncOperations from '../../operations';
import { connect } from 'react-redux';
import CustomInfinitePartnersGridHits from './custom-infinite-partners-grid-hits';
import { Index } from 'react-instantsearch-dom';
import { CustomTabs } from '../../../../components/ui';

const { useTranslation, Link } = require('../../../../utils/i18n/i18n');

interface ISearchProductsBlock {
    onSearchStateChange: any;
    searchState: any;
    resultsState: any;
}

const SearchProductsBlock: React.FunctionComponent<ISearchProductsBlock> = ({
    onSearchStateChange,
    searchState,
    resultsState
}) => {
    const { t } = useTranslation();
    const theme: Theme = useTheme();

    return (
        <MaxWidthWrapper color={'white'}>
            <InstantSearch
                stalledSearchDelay={350}
                onSearchStateChange={onSearchStateChange}
                resultsState={resultsState}
                searchState={searchState ? searchState : {}}
                searchClient={searchClient}
                indexName={indexName}>
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        max-width: 100vw;
                        padding: 2rem ${theme?.layout.sidePadding[0]};
                    `}>
                    <H2
                        css={css`
                            text-align: center;
                        `}>
                        {t('products-display:home-page-short-slogan')}
                    </H2>
                    <Link
                        className={'text-blue-500 hover:text-blue-800 hover:underline'}
                        href={'/'}>
                        <a>Click here to learn more about Coresine</a>
                    </Link>
                    <CustomSearchbox />
                    <CustomTabs id={'products-and-businesses'} animate={true} large={true}>
                        <Tab
                            id={'businesses'}
                            title={'Businesses'}
                            panel={
                                <Index indexName={'businesses_mvp-prod'}>
                                    <CustomInfinitePartnersGridHits />
                                </Index>
                            }
                        />
                        <Tab
                            id={'products'}
                            title={'Products'}
                            panel={
                                <Index indexName={indexName}>
                                    <CustomInfiniteProductsGridHits />
                                </Index>
                            }
                        />
                    </CustomTabs>
                </div>
            </InstantSearch>
        </MaxWidthWrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        searchState: state.homePageState.searchState,
        resultsState: state.homePageState.resultsState
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onSearchStateChange: (newSearchState: string) =>
            dispatch(AsyncOperations.changeSearchState(newSearchState))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchProductsBlock);
