import React from 'react';
import { css } from '@emotion/core';
import { Card, Dialog } from '@blueprintjs/core';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import PriceBlock from './components/price-block';
import TitleBlock from '../common/components/title-block';
import DescriptionBlock from '../common/components/description-block';
import PartnerBlock from './components/partner-block';
import { Image, Transformation } from 'cloudinary-react';
import { IOffering } from '../../../modules/products-display/types';
import ImageWrapper from '../common/components/image-wrapper';
import CardWrapper from '../common/components/card-wrapper';
import DialogWrapper from '../common/components/dialog-wrapper';

const attributePlaceholder = styled('div')({
    borderRadius: '50%',
    width: '1rem',
    height: '1rem'
});

const attributesPlaceholder = [];
for (let i = 0; i < 5; i++) {
    attributesPlaceholder.push(attributePlaceholder);
}

const ProductCard: React.FunctionComponent<IOffering> = ({
    name,
    _id,
    description,
    price,
    ref_price,
    business,
    image
}) => {
    const [isDetailedCardOpen, setIsDetailedCardOpen] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        if (window.innerWidth < 576) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    // const onClickHandler = () => {
    //   if ( isMobile ) {
    //     pushToRoute(`/offerings/${_id}`);
    //   } else {
    //     setIsDetailedCardOpen(true);
    //   }
    // }

    const onCloseHandler = () => {
        setIsDetailedCardOpen(false);
    };

    return (
        <section>
            <DialogWrapper>
                <Dialog
                    lazy={true}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    title={''}
                    isOpen={isDetailedCardOpen}
                    onClose={onCloseHandler}></Dialog>
            </DialogWrapper>
            <CardWrapper typeof={'product'}>
                <Card
                    interactive={false}
                    css={css`
                        height: auto !important;
                    `}>
                    <ImageWrapper>
                        <Image
                            cloudName={'coresine'}
                            alt={'The picture of the product'}
                            secure={true}
                            publicId={`products/${image}`}
                            css={css`
                                object-fit: contain;
                                border-radius: 5px 5px 0 0;
                                padding: 0.75rem;
                            `}>
                            <Transformation flags={'progressive:semi'} />
                        </Image>
                    </ImageWrapper>
                    <div
                        css={css`
                            flex: 2;
                            margin-top: 0.5rem;
                        `}>
                        <TitleBlock isOverlay={false} title={name} mode={'basic'} />
                        {!isMobile ? (
                            <>
                                <PartnerBlock business={business} mode={'basic'} />
                                <DescriptionBlock description={description} />
                            </>
                        ) : (
                            <>
                                <PartnerBlock business={business} mode={'basic'} />
                            </>
                        )}
                        <div
                            css={css`
                                display: flex;
                                justify-content: space-between;
                            `}>
                            <PriceBlock price={price} ref_price={ref_price} size={'small'} />
                        </div>
                    </div>
                </Card>
            </CardWrapper>
        </section>
    );
};

export default ProductCard;
