import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../../utils/theming/theming';

const ImageWrapper = styled.div`
    position: relative;
    top: -0.2rem;
    left: -0.2rem;
    width: 8.5rem;
    img {
        width: 8.5rem;
        height: 8.5rem;
    }
    ${theme.layout.mq[0]} {
        top: -0.5rem;
        left: -0.5rem;
        width: 14.5rem;
        img {
            width: 14.5rem;
            height: 14.5rem;
        }
    }
`;

export default ImageWrapper;
