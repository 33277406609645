import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../../utils/theming/theming';

const CardWrapper = styled.div`
    width: 100%;
    > div {
        display: flex;
        flex-direction: column;
        width: 8.5rem;
        height: ${(props) => (props['typeof'] === 'product' ? '18rem' : '18.5rem')};
        padding: 0.2rem;
        ${theme.layout.mq[0]} {
            width: 14.5rem;
            height: ${(props) => (props['typeof'] === 'product' ? '22rem' : '19.5rem')};
            padding: 0.5rem;
        }
    }
`;

export default CardWrapper;
