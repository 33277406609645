import React, { Dispatch } from 'react';
import { css } from '@emotion/core';
import { TabsButtonGroup } from './components';

interface IFilterBlock {
    homePageTab: string;
    setHomePageTab: Dispatch<string>;
}

const FilterBlock: React.FC<IFilterBlock> = ({ homePageTab, setHomePageTab }) => {
    return (
        <section
            className={'grid gap-y-4'}
            css={css`
                grid-auto-rows: min-content;
            `}>
            <h3 className={'text-base font-bold'}> Filters </h3>
            <TabsButtonGroup homePageTab={homePageTab} setHomePageTab={setHomePageTab} />
            {/* Other filters that will be added later */}
        </section>
    );
};

export default FilterBlock;
