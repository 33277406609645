import { findResultsState } from 'react-instantsearch-dom/server';
import algoliasearch from 'algoliasearch/lite';
import { LogLevelEnum } from '@algolia/logger-common';
import { createConsoleLogger } from '@algolia/logger-console';
import { createFallbackableCache } from '@algolia/cache-common';
import { version } from '@algolia/client-common';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import { createBrowserLocalStorageCache } from '@algolia/cache-browser-local-storage';

const indexName = 'store_products_mvp-prod';

const searchClient = algoliasearch('9L6MOS2H2S', '1d66e40b20ae6fe9878c191265cbeed4', {
    hostsCache: createFallbackableCache({
        caches: [
            createBrowserLocalStorageCache({ key: `${version}-${'9L6MOS2H2S'}` }),
            createInMemoryCache()
        ]
    }),
    logger: createConsoleLogger(LogLevelEnum.Debug)
});

export { findResultsState, indexName, searchClient };
