import 'react';
import { css } from '@emotion/core';
import SearchProductsBlock from './components/search-products-block';
import qs from 'qs';
import { findResultsState, indexName, searchClient } from '../instantsearch';
import { Dispatch, useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as AsyncOperations from '../operations';
import { IOffering } from '../types';
import PartnersPanel from './components/components/partners-panel';
import { QuickAccessBlock } from './components';
import OfferingsPanel from './components/components/offerings-panel';
import { IShareableList } from '@interfaces/shareable-lists';
import useMedia from 'use-media';

interface IHomePageIndex {
    homePageTab: string;
    onSearchStateChange: any;
    onResultsChange: any;
    setHomePageTab: Dispatch<string>;
    shareableLists: IShareableList[];
}

const HomePageIndex: React.FunctionComponent<IHomePageIndex> = ({
    onSearchStateChange,
    onResultsChange,
    homePageTab,
    shareableLists,
    setHomePageTab
}) => {
    const isDesktop: boolean = useMedia({ minWidth: 900 });

    useEffect(() => {
        onSearchStateChange(qs.parse(window.location.search.slice(1)));
        async () => {
            onResultsChange(
                await findResultsState(SearchProductsBlock, { indexName, searchClient })
            );
        };
    }, []);

    return (
        <main className={'mt-12 flex bg-white justify-center'}>
            <div
                className={'grid w-full bg-white pb-40'}
                css={css`
                    ${!isDesktop && `grid-template-columns: 12fr;`}
                    ${isDesktop && `grid-template-columns: 9fr 15rem;`}
            gap: 0 1rem;
                    min-height: calc(100vh - 3rem);
                    margin-right: 5%;
                    margin-left: 5%;
                `}>
                {homePageTab === 'partners' && <PartnersPanel />}
                {homePageTab === 'offerings' && <OfferingsPanel />}
                {isDesktop && (
                    <QuickAccessBlock
                        shareableLists={shareableLists}
                        homePageTab={homePageTab}
                        setHomePageTab={setHomePageTab}
                    />
                )}
            </div>
        </main>
    );
};

const mapStateToProps = (state) => {
    return {
        homePageTab: state.homePageState.homePageTab,
        searchState: state.homePageState.searchState,
        shareableLists: state.shareableListsPageState.lists
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onSearchStateChange: (newSearchState: string) =>
            dispatch(AsyncOperations.changeSearchState(newSearchState)),
        onResultsChange: (newResults: IOffering[]) =>
            dispatch(AsyncOperations.changeResults(newResults)),
        setHomePageTab: (newTabId: string) => dispatch(AsyncOperations.setHomePageTab(newTabId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageIndex);
