import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../../utils/theming/theming';

const CardsGridWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 8rem);
    justify-content: flex-start;
    gap: 1rem;
    align-items: flex-start;
    list-style-type: none;
    padding-inline-start: 0;
    ${theme.layout.mq[0]} {
        grid-template-columns: repeat(auto-fill, 14.5rem);
    }
`;

export default CardsGridWrapper;
