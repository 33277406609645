import React from 'react';
import { css } from '@emotion/core';
import { ProgressiveImage } from '../../../common';
import TitleBlock from '../../common/components/title-block';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../utils/theming/theme-type';

interface IPostContent {
    image?: string;
    title: string;
    text: string;
}

const PostContent: React.FunctionComponent<IPostContent> = ({ image, title, text }) => {
    const theme: Theme = useTheme();

    return (
        <div
            className={'px-4'}
            css={css`
                display: flex;
                flex-direction: column;
            `}>
            {image && <ProgressiveImage className={'h-full w-full'} src={null} />}
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                `}>
                <TitleBlock title={title} mode={'basic'} />
                <p
                    css={css`
                        font-size: ${theme.fontSize.default};
                    `}>
                    {text}
                </p>
            </div>
            {/*<Divider />*/}
            {/*<ButtonGroup minimal={true}*/}
            {/*             large={false}*/}
            {/*             css={css`*/}
            {/*  display: flex;*/}
            {/*  justify-content: space-between;*/}
            {/*  padding: 0 0.5rem;*/}
            {/*  padding-bottom: 0.5rem;*/}
            {/*`} >*/}
            {/*  <Button icon={'circle'} fill={true} intent={Intent.DANGER} text={'Like'}  />*/}
            {/*  <Button icon={'comment'} fill={true} text={'Comment'} />*/}
            {/*  <Button icon={'share'} fill={true} text={'Share'} />*/}
            {/*</ButtonGroup>*/}
        </div>
    );
};

export default PostContent;
