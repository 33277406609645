import React from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import CardsGridWrapper from './cards-grid-wrapper';
import withRenderUI from '../../../../hoc/render-ui-hoc';
import { PartnerCardV2 } from '../../../../components/cards/partner-card';

const InfiniteHits: React.FunctionComponent = ({ hits }: any) => {
    return (
        <CardsGridWrapper>
            {hits?.map((hit: any) => (
                <PartnerCardV2 {...hit} />
            ))}
        </CardsGridWrapper>
    );
};

const CustomInfinitePartnersGridHits = connectInfiniteHits(withRenderUI(InfiniteHits));

export default CustomInfinitePartnersGridHits;
