import React, { Dispatch } from 'react';
import { ButtonGroup, Intent } from '@blueprintjs/core';
import { Button } from '@components/ui';

interface ITabsButtonGroup {
    homePageTab: string;
    setHomePageTab: Dispatch<string>;
}

const TabsButtonGroup: React.FC<ITabsButtonGroup> = ({ homePageTab, setHomePageTab }) => {
    const setHomePageTabToPartners = () => {
        setHomePageTab('partners');
    };

    const setHomePageTabToOfferings = () => {
        setHomePageTab('offerings');
    };

    return (
        <ButtonGroup fill={true} large={false} minimal={false}>
            <Button
                text={'Partners'}
                intent={homePageTab === 'partners' ? Intent.PRIMARY : null}
                onClick={setHomePageTabToPartners}
            />
            <Button
                text={'Offerings'}
                intent={homePageTab === 'offerings' ? Intent.PRIMARY : null}
                onClick={setHomePageTabToOfferings}
            />
        </ButtonGroup>
    );
};

export default TabsButtonGroup;
