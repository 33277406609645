import React from 'react';
import { searchClient } from '../../../instantsearch';
import CustomSearchbox from '../custom-searchbox';
import { InstantSearch } from 'react-instantsearch-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as AsyncOperations from '../../../operations';
import CustomInfiniteHits from './components/custom-infinite-hits';

interface IPartnersPanel {
    onSearchStateChange: any;
    resultsState: any;
    searchState: any;
}

const PartnersPanel: React.FunctionComponent<IPartnersPanel> = ({
    searchState,
    resultsState,
    onSearchStateChange
}) => {
    return (
        <div className={'flex flex-col '}>
            <InstantSearch
                stalledSearchDelay={350}
                onSearchStateChange={onSearchStateChange}
                resultsState={resultsState}
                searchState={searchState ? searchState : {}}
                searchClient={searchClient}
                indexName={process.env.NEXT_PUBLIC_PARTNERS_INDEX}>
                <div className={'w-1/2 mt-4'}>
                    <CustomSearchbox />
                </div>
                <CustomInfiniteHits />
            </InstantSearch>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        searchState: state.homePageState.searchState,
        resultsState: state.homePageState.resultsState
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onSearchStateChange: (newSearchState: string) =>
            dispatch(AsyncOperations.changeSearchState(newSearchState))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnersPanel);
