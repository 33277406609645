import React from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { ProductCard } from '../../../../components/cards/product-card';
import CardsGridWrapper from './cards-grid-wrapper';
import withRenderUI from '../../../../hoc/render-ui-hoc';

const InfiniteHits: React.FunctionComponent = ({ hits }: any) => {
    return (
        <CardsGridWrapper>
            {hits?.map((hit: any) => (
                <ProductCard {...hit} />
            ))}
        </CardsGridWrapper>
    );
};

const CustomInfiniteProductsGridHits = connectInfiniteHits(withRenderUI(InfiniteHits));

export default CustomInfiniteProductsGridHits;
