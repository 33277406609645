export const HOME_PAGE_CHANGE_SEARCH_STATE = 'HOME_PAGE_CHANGE_SEARCH_STATE';
export const HOME_PAGE_CHANGE_RESULTS_STATE = 'HOME_PAGE_CHANGE_SEARCH_STATE';
export const HOME_PAGE_SET_TAB = 'HOME_PAGE_SET_TAB';

export const DETAILED_PARTNER_PAGE_FETCH_PARTNER_ASYNC =
    'DETAILED_PARTNER_PAGE_FETCH_PARTNER_ASYNC';
export const DETAILED_PARTNER_PAGE_FETCH_PARTNER_SUCCESS =
    'DETAILED_PARTNER_PAGE_FETCH_PARTNER_SUCCESS';
export const DETAILED_PARTNER_PAGE_FETCH_PARTNER_FAILURE =
    'DETAILED_PARTNER_PAGE_FETCH_PARTNER_FAILURE';
