import React from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { css } from '@emotion/core';
import CardsGridWrapper from '../../cards-grid-wrapper';
import { PartnerDto } from '@services/partners-service/dto';
import PartnerCardV2 from '../../../../../../components/cards/partner-card/partner-card-v2';
import { InView } from 'react-intersection-observer';
import { Intent, Spinner } from '@blueprintjs/core';

const InfiniteHits = ({ hits, refineNext, hasMore }) => {
    const [isFetching, setIsFetching] = React.useState<boolean>(false);

    const onEntryChangeHandler = (inView) => {
        if (inView && hasMore) {
            setIsFetching(true);
            refineNext();
            setIsFetching(false);
        }
    };

    return (
        <div className={'w-full mt-4'}>
            <CardsGridWrapper>
                {hits.map((hit: any) => {
                    const newPartner = new PartnerDto(hit);
                    return <PartnerCardV2 hit={newPartner} key={newPartner.id} />;
                })}
                <InView
                    as={'section'}
                    onChange={onEntryChangeHandler}
                    css={css`
                        position: relative;
                        top: -5rem;
                    `}>
                    <section />
                </InView>
            </CardsGridWrapper>
            {isFetching && <Spinner intent={Intent.PRIMARY} size={50} />}
        </div>
    );
};

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

export default CustomInfiniteHits;
