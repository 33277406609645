import 'react';
import { css } from '@emotion/core';
import { HTMLTable } from '@blueprintjs/core';
import { INutrition, IProductDetails } from '../../../../modules/products-display/types';

/**
 *
 * @param nutrition - data on nutritional value of the product
 * @constructor
 */
const TableBlock = ({ nutrition }: IProductDetails) => {
    return (
        <HTMLTable
            interactive={true}
            striped={true}
            css={css`
                width: 100%;
            `}>
            <thead>
                <tr>
                    <td> </td>
                    <td>
                        {' '}
                        Per serving <strong> 30g </strong>{' '}
                    </td>
                    <td>
                        {' '}
                        Per <strong> 100g </strong>{' '}
                    </td>
                </tr>
            </thead>
            <tbody>
                {nutrition.map((oneProduct: INutrition) => {
                    return (
                        <tr>
                            <td> {oneProduct.title} </td>
                            <td> {oneProduct.value} </td>
                            <td> {oneProduct.standard_value} </td>
                        </tr>
                    );
                })}
            </tbody>
        </HTMLTable>
    );
};

export default TableBlock;
