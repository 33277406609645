import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import { Tag } from '@blueprintjs/core';
import Theme from '../../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';

interface IGroupBlock {
    groups: string[];
}

const GroupBlock: React.FunctionComponent<IGroupBlock> = ({ groups }) => {
    const theme: Theme = useTheme();
    const [chosenGroups, setChosenGroups] = useState<string>();

    const onClickHandler = (group: string): void => {
        setChosenGroups(group);
    };

    console.log('chosenGroups: ', chosenGroups);

    return (
        <section
            css={css`
                display: grid;
                grid-auto-flow: column;
                justify-content: start;
                height: 1.75rem;
                gap: 0.5rem;
                width: 100%;
                overflow-x: scroll;
                margin-bottom: 0.5rem;
                ::-webkit-scrollbar {
                    display: none;
                }
            `}>
            {groups.map((group: string) => (
                <Tag
                    intent={'warning'}
                    interactive={true}
                    active={false}
                    round={true}
                    large={false}
                    onClick={() => onClickHandler(group)}
                    css={css`
                                              width: fit-content;
                                              border:  ${
                                                  chosenGroups !== group &&
                                                  '2px solid' + theme.colors.warning + '!important;'
                                              }
                                              color:  ${
                                                  chosenGroups !== group &&
                                                  theme.colors.warning + '!important;'
                                              }
                                              background-color: ${
                                                  chosenGroups !== group && 'white !important;'
                                              }
                                            `}>
                    {' '}
                    {group}{' '}
                </Tag>
            ))}
        </section>
    );
};

export default GroupBlock;
